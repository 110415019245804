import { Chunk, getSegment, Point, TrackTooltip } from 'components';
import { isTouchDevice } from 'other';

import { L, TVesselTrackPoint } from 'types';
import { TBasicTrackOptions } from './helpers';

/**
 *
 */
export abstract class AbstractTrack<T extends TBasicTrackOptions> {
  public readonly options: T;
  protected isTouchDevice = isTouchDevice();
  protected points: L.CircleMarker[];
  // To increase click tolerance a thicker transparent track is also drawn.
  protected segmentsHidden: L.Polyline[];
  protected segmentsVisible: L.Polyline[];
  protected tooltip: TrackTooltip;
  protected trackVisible: L.Polyline;

  /**/
  protected static data2points(data: TVesselTrackPoint[]): [number, number][] {
    if (!data) return [];
    // return data.map(({ id, latitude, longitude }) => window.L.latLng(latitude, longitude, id)) as any;
    return data.map(({ latitude, longitude }) => [latitude, longitude]);
  }

  /**/
  constructor(options: T) {
    this.options = options;
    this.init();
  }

  /**/
  protected abstract init(): void;

  /**/
  protected abstract drawVisible(chunks: Chunk[]): void;

  /**/
  protected onLineClick = (e: L.LeafletMouseEvent) => {
    const { onClick, path } = this.options;
    // console.log(this.findPoint(e));
    onClick(path.vesselId, this.findPoint(e), e.latlng);
  };

  /**/
  private findPoint(e: L.LeafletMouseEvent): TVesselTrackPoint {
    const { map, path } = this.options;
    const {
      latlng,
      sourceTarget: { options }
    } = e;

    const [tailPointDist, headPointDist] = options['dataPoints'].map((ll) =>
      map.distance(ll, latlng)
    );

    const [latitude, longitude] =
      tailPointDist < headPointDist
        ? options['dataPoints'][0]
        : options['dataPoints'][1];

    return path.locations?.find(
      (p: TVesselTrackPoint) =>
        p.latitude === latitude && p.longitude === longitude
    );
  }

  /**/
  public abstract remove(): void;

  // /**/
  // protected createHiddenChunks(): [number, number][][] {
  //   return (this.options.path.locations || [])
  //     .map((p: TVesselTrackPoint, idx: number, arr: TVesselTrackPoint[]) => {
  //       const nextPoint = arr[idx + 1];
  //       return nextPoint && AbstractTrack.data2points([p, nextPoint]);
  //     })
  //     .filter(Boolean);
  // }
  //
  // /**/
  // protected drawHidden(chunks: [number, number][][]): void {
  //   const { map, path, showTooltip } = this.options;
  //   const onHover =
  //     showTooltip && !this.isTouchDevice
  //       ? this.createTooltip
  //       : Function.prototype;
  //
  //   this.segmentsHidden = chunks.map(
  //     (ch: [number, number][]): L.Polyline =>
  //       window.L.polyline(ch, {
  //         color: 'transparent',
  //         // @ts-ignore
  //         dataPoints: ch,
  //         pane: 'markerPane',
  //         // @ts-ignore
  //         vesselId: path.vesselId,
  //         weight: this.isTouchDevice ? 20 : 10
  //       })
  //         .addTo(map)
  //         .on('click', this.onLineClick)
  //         .on('mouseover', onHover as any)
  //         .on('mouseout', this.removeTooltip)
  //   );
  // }

  /**/
  protected handleClick = (e: L.LeafletMouseEvent) => {
    const { map, onClick, path } = this.options;
    onClick(
      path.vesselId,
      path.locations &&
        path.locations[getSegment(map, e.latlng, path.locations).index],
      e.latlng
    );
  };

  /**/
  protected createTooltip = (e: L.LeafletMouseEvent): void => {
    const { map, path } = this.options;
    if (!('name' in path)) return;

    const point = path.locations[path.locations.length - 1];
    const data: Point = {
      flag: path.flag,
      lastUpdate: point.lastUpdate,
      name: path.name,
      speed: point.speed
    };

    this.tooltip = new TrackTooltip(map, e.latlng, data);
  };

  /**/
  protected removeTooltip = () => this.tooltip?.remove();
}
