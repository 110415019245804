import { AbstractTrack } from './AbstractTrack';
import { getSegment, TSimpleTrackOptions } from './helpers';
import { L } from 'types';

/**
 *
 */
export class SimpleTrack extends AbstractTrack<TSimpleTrackOptions> {
  /**/
  protected init(): void {
    this.drawVisible();
    // this.drawHidden(this.createHiddenChunks());
  }

  /**/
  public remove() {
    this.tooltip?.remove();
    this.segmentsHidden?.forEach((s) => s.remove());
    this.trackVisible?.off();
    this.trackVisible?.removeFrom(this.options.map);
  }

  /**/
  protected drawVisible(): void {
    const { color, map, onClick, path, showTooltip } = this.options;
    const onHover =
      showTooltip && !this.isTouchDevice
        ? this.createTooltip
        : Function.prototype;

    if (!path.locations) return;

    this.trackVisible = window.L.polyline(
      SimpleTrack.data2points(path.locations),
      {
        color: color,
        renderer: window.L.canvas({
          tolerance: this.isTouchDevice ? 8 : 5
        }),
        weight: 2
      }
    )
      .addTo(map)
      // .on('click', (e: L.LeafletMouseEvent) =>
      //   onClick(
      //     path.vesselId,
      //     path.locations && path.locations[getSegment(map, e).index],
      //     e.latlng
      //   )
      // )
      .on('click', this.handleClick)
      .on('mouseover', onHover as any)
      .on('mouseout', this.removeTooltip);
  }
}
