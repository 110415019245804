import React from 'react';
import dayjs from 'dayjs';
import notification from 'antd/lib/notification';

import { SettingsService } from './settings';
import { store } from '../store';
import { EUserAuthority } from '../types';
import { CHUNK_LOAD_ERROR_FRAGMENTS } from '../other';

/**
 *
 */
export function getBuildInfo() {
  window.addEventListener('error', onError);

  fetchBuildInfo().then((info) => {
    const href = window.location.href;
    info &&
      SettingsService.writeSettings({ [SettingsService.BUILD_INFO]: info });

    if (
      href.includes('test') ||
      href.includes('staging') ||
      href.includes('demo')
    ) {
      window.console.log('Build details:\n', JSON.stringify(info, null, 2));
    }
  });
}

/**
 *
 */
function fetchBuildInfo() {
  return fetch(`${window.location.origin}/build.json?q=${Math.random()}`)
    .then((r) => r.json())
    .catch((e) => {
      if (
        e.message.includes('JSON.parse') ||
        e.message.includes('is not valid JSON')
      ) {
        return window.console.info('No build data found.');
      }
      window.console.error(e);
    });
}

/**
 *
 */
function onError(e) {
  const buildInfo = SettingsService.readSettings()[SettingsService.BUILD_INFO];
  const isChunkLoadError = CHUNK_LOAD_ERROR_FRAGMENTS.some((text) =>
    e.message.includes(text)
  );

  window.console.log(e.message || e.error?.message);
  window.console.log(buildInfo);

  if (isChunkLoadError) {
    const buildReloadTime =
      SettingsService.readSettings()[SettingsService.BUILD_RELOAD_TIMESTAMP];

    const diff = dayjs().diff(buildReloadTime || dayjs(), 'hour');
    const BUILD_UPDATE_INTERVAL = 24; // hours

    fetchBuildInfo().then((info) => {
      window.console.log(info);

      if (
        (info?.buildHash &&
          buildInfo?.buildHash &&
          info.buildHash !== buildInfo.buildHash) ||
        diff < BUILD_UPDATE_INTERVAL
      ) {
        SettingsService.writeSettings({
          [SettingsService.BUILD_RELOAD_TIMESTAMP]: Date.now()
        });
        window.location.reload(true);
        window.location = window.location.href;
      }
    });
  }

  e.code !== 401 && displayError(e);
}

/**
 *
 */
function displayError(e) {
  const { session } = store.getState();
  const userInfo = session.user?.userInfo || {
    authorities: []
  };

  const isDev = userInfo.authorities.includes(EUserAuthority.DEVELOPER);
  if (!isDev) return;

  const desc = (
    <div style={{ fontSize: '10px' }}>
      {(e.stack || e.error?.stack)?.slice(0, 400)}
      <div>...</div>
    </div>
  );

  notification.error({
    description: desc,
    duration: 0,
    message: <div style={{ fontSize: '14px' }}>{e.message.slice(0, 150)}</div>
  });
}
