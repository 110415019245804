import { AbstractTrack } from './AbstractTrack';
import { Chunk, COLOR_MAP, ESpeed, TSpeedTrackOptions } from './helpers';
import { L, TVesselTrackPoint } from 'types';

/**
 * Draws a track which segments are colored according to the speed.
 */
export class SpeedTrack extends AbstractTrack<TSpeedTrackOptions> {
  /**/
  private static createVisibleChunk(speedRange: number): Chunk {
    return {
      color: COLOR_MAP[speedRange],
      data: []
    };
  }

  /**/
  private static defineSpeed(speed: number): number {
    if (speed <= 0.3 || !speed) {
      return ESpeed.S0;
    } else if (speed > 0.3 && speed <= 1) {
      return ESpeed.S1;
    } else if (speed > 1 && speed <= 3) {
      return ESpeed.S2;
    } else if (speed > 3 && speed <= 4) {
      return ESpeed.S3;
    } else if (speed > 4 && speed <= 5.5) {
      return ESpeed.S4;
    } else if (speed > 5.5 && speed <= 7) {
      return ESpeed.S5;
    } else if (speed > 7 && speed <= 10) {
      return ESpeed.S6;
    } else if (speed > 10 && speed <= 13) {
      return ESpeed.S7;
    }
    return ESpeed.S8;
  }

  /**/
  private static drawPoint(
    p: TVesselTrackPoint,
    idx: number,
    arr: TVesselTrackPoint[]
  ): L.CircleMarker {
    const isLast = idx === arr.length - 1;

    return window.L.circleMarker([p.latitude, p.longitude], {
      color: isLast ? 'transparent' : 'black',
      fill: false,
      // @ts-ignore
      point: p,
      radius: isLast ? 0 : 2,
      weight: 1
    });
  }

  /**/
  public remove() {
    this.tooltip?.remove();
    this.points?.forEach((p) => p.remove());
    this.segmentsHidden?.forEach((s) => s.remove());
    this.segmentsVisible?.forEach((s) => s.remove());
  }

  /**/
  init() {
    this.drawVisible(this.createVisibleChunks());
    // this.drawHidden(this.createHiddenChunks());

    if (this.options.showDots) {
      this.points = this.options.path.locations?.map((...args) =>
        SpeedTrack.drawPoint(...args)
          .addTo(this.options.map)
          .on('click', this.onPointClick)
      );
    }
  }

  /**/
  private onPointClick = (e: L.LeafletMouseEvent) => {
    const {
      onClick,
      path: { vesselId }
    } = this.options;
    const {
      latlng,
      sourceTarget: { options }
    } = e;
    onClick(vesselId, options.point, latlng);
  };

  /**/
  private createVisibleChunks(): Chunk[] {
    let currentSpeed;
    let currentChunkIdx = -1;
    const chunks: Chunk[] = [];

    this.options.path.locations?.forEach(
      (p: TVesselTrackPoint, idx: number, arr: TVesselTrackPoint[]) => {
        const nextPoint = arr[idx + 1];
        const speedRange = SpeedTrack.defineSpeed(nextPoint?.speed || p?.speed);

        // Start a new chunk
        if (currentSpeed !== speedRange) {
          if (currentChunkIdx >= 0) {
            chunks[currentChunkIdx].data.push(p);
          }
          currentSpeed = speedRange;
          currentChunkIdx++;

          const chunk = SpeedTrack.createVisibleChunk(speedRange);
          chunk.data[0] = p;
          chunks[currentChunkIdx] = chunk;
        } else {
          chunks[currentChunkIdx].data.push(p);
        }
      }
    );

    // Preserve for debug.
    // chunks.map((c) => {
    //   const points = c.data.map(d => d.speed).join(', ');
    //   window.console.log(`%c ${points} `, ['background-color: ' + c.color]);
    // });

    return chunks;
  }

  /**/
  protected drawVisible(chunks: Chunk[]): void {
    const { map, showTooltip } = this.options;
    const renderer = window.L.canvas({
      tolerance: this.isTouchDevice ? 8 : 5
    });

    const onHover =
      showTooltip && !this.isTouchDevice
        ? this.createTooltip
        : Function.prototype;

    this.segmentsVisible = chunks.map(
      (ch: Chunk): L.Polyline =>
        window.L.polyline(SpeedTrack.data2points(ch.data), {
          color: ch.color,
          renderer: renderer,
          weight: 2
        })
          .addTo(map)
          .on('click', this.handleClick)
          .on('mouseover', onHover as any)
          .on('mouseout', this.removeTooltip)
    );
  }
}
